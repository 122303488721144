import React, { useState } from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button onClick={togglePopup} className="privacy-button">
        Privacy Policy
      </button>
      {isOpen && (
        <div className="privacy-popup">
          <div className="popup-content">
            <button onClick={togglePopup} className="close-button">X</button>
            <h1>Privacy Policy</h1>
            <p>Effective Date: [20/11/2024]</p>
            <p>
                Finalyst, Inc. ("we," "our," or "us") is committed to safeguarding your privacy. This Privacy Policy 
                outlines how we collect, use, disclose, and protect your personal information when you interact with 
                our waitlist platform for the upcoming release of our investment research services. By using our website 
                or app, you consent to the practices described in this Privacy Policy.
            </p>

            <h2>1. Information We Collect</h2>
            <p>We collect the following types of information:</p>
            <ul>
                <li>
                    <strong>Personal Information:</strong> Information that identifies you as an individual, including:
                    <ul>
                        <li>Name</li>
                        <li>Email address</li>
                    </ul>
                </li>
                <li>
                    <strong>Usage Data:</strong> Information about your interactions with our platform, such as pages visited 
                    and forms submitted. This is used to improve user experience.
                </li>
            </ul>
            <p>
                We do not collect sensitive data such as health, biometric, or government identification details.
            </p>

            <h2>2. How We Collect Your Information</h2>
            <p>We gather your information through the following methods:</p>
            <ul>
                <li>Directly from you when you complete forms on our platform, such as waitlist registration.</li>
                <li>Through interactions with our customer service team or email communications.</li>
                <li>Automatically via third-party tools integrated into our platform for enhanced functionality.</li>
            </ul>

            <h2>3. Purpose of Data Collection</h2>
            <p>Your information is collected and used for the following purposes:</p>
            <ul>
                <li>
                    <strong>Communication:</strong> To provide updates, announcements, and important information about our product release.
                </li>
                <li>
                    <strong>Service Improvement:</strong> To analyze user behavior and improve the functionality and usability of our platform.
                </li>
                <li>
                    <strong>Marketing:</strong> To share promotional materials, including newsletters and offers related to our services.
                </li>
            </ul>
            <p>We do not sell or rent your personal information to third parties.</p>

            <h2>4. Data Storage and Security</h2>
            <p>Your data is securely stored on AWS servers. We use industry-standard measures to protect your information, including:</p>
            <ul>
                <li>Encryption of sensitive data during transmission and at rest.</li>
                <li>Access control protocols to restrict unauthorized access to your data.</li>
            </ul>
            <p>
                Your data is retained indefinitely unless you request deletion or unless required by law to retain it for specific purposes.
            </p>

            <h2>5. Your Rights</h2>
            <p>You have the following rights regarding your data:</p>
            <ul>
                <li>
                    <strong>Access:</strong> You can request a copy of your personal data that we hold.
                </li>
                <li>
                    <strong>Correction:</strong> You can request corrections to your data if it is inaccurate or incomplete.
                </li>
                <li>
                    <strong>Deletion:</strong> You can request that we delete your data, subject to legal or operational requirements.
                </li>
                <li>
                    <strong>Opt-Out:</strong> You can opt out of receiving marketing communications by following the unsubscribe instructions 
                    provided in emails or contacting us directly.
                </li>
            </ul>

            <h2>6. Cookies and Tracking</h2>
            <p>Our waitlist platform does not use cookies or other tracking technologies at this time.</p>

            <h2>7. Sharing Your Information</h2>
            <p>We do not share your personal information with third parties, except in the following scenarios:</p>
            <ul>
                <li>
                    When required by law or regulatory authorities to comply with legal obligations.
                </li>
                <li>
                    To protect our rights, property, or safety, or that of our users or others.
                </li>
            </ul>

            <h2>8. Children's Privacy</h2>
            <p>
                Our platform is not intended for use by children under the age of 13. We do not knowingly collect personal data from 
                children. If we become aware that a child has provided us with personal data, we will delete such information.
            </p>

            <h2>9. Updates to the Privacy Policy</h2>
            <p>
                We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting 
                on our platform, and we will notify you of significant changes via email or through the platform itself.
            </p>

            <h2>10. Contact Us</h2>
            <p>If you have any questions or concerns about this Privacy Policy, please contact us:</p>
            <p>Email: <a href="mailto:hello@finalyst.xyz">hello@finalyst.xyz</a></p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PrivacyPolicy;
