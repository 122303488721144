import './App.css';
import { Navbar, Hero, Footer} from './components';
import PrivacyPolicy from './components/policies/PrivacyPolicy';

function App() {
  return (
    <>
    <div><Navbar /></div>
    <div><Hero /></div>
    <div><Footer /></div>
    <div style={{ textAlign: 'center', margin: '20px 0' }}>
        <PrivacyPolicy />
    </div>
    </>
  );
}

export default App;
